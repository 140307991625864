<template>
  <div class="tab-contents-wrap">
    <!-- Reservation List -->
    <div class="board-list-wrap">
      <template v-if="statusType === true">
      <div class="board-top-area">
        <div class="icon-notice">{{$t('content.event.EventReservation.txt124')}}</div>
        <div class="total-count">
          {{$t('content.event.EventReservation.txt125')}}
          <em class="space">{{vunues.length}}</em>{{$t('content.event.EventReservation.txt126')}}
        </div>
      </div>
      <ul class="board-list">
        <template v-for="(v, i) in vunues.slice(0, increase)">
          <li>
            <div class="list-item">
              <dl class="info">
                <dt>{{v.reservedPlaceNames}}</dt>
                <dd>
                  <ul class="info-detail">
                    <li class="date">
                      <time>
                        {{ getDateString(v.startTime, v.endTime, $i18n.locale) }}
                      </time>
                    </li>
                    <li>
                      <span>{{$t('content.event.EventReservation.txt127')}} :</span>
                      <time>{{$moment(v.insertDate).format('YYYY.MM.DD HH:mm')}}</time>
                    </li>
                    <li class="v-bar">
                      <!-- <span>{{$t('content.users.ServiceInfo.txt13')}} :</span> -->
                      <!-- <template v-if="v.reservationState === '9010'">
                        <span>
                          {{v.reservationStateName}}
                        </span>
                      </template> -->
                      <template>
                        <span>
                          {{$t('content.users.ServiceInfo.txt13')}} :
                          {{v.reservationStateName}}
                          ({{$moment(v.borrowChangeStatusDate).format('YYYY.MM.DD HH:mm')}})
                        </span>
                      </template>
                    </li>
                  </ul>
                </dd>
              </dl>
              <div class="right-area">
                <!-- <button class="btn-ghost btn-sm">신청 취소</button> -->
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="btn-wrap">
        <a href="#" @click="goBack" class="back">Back</a>
        <button button class="btn-more" @click="addVunues" v-if="vunues.length>increase">
          <span class="circle">
            <span class="icon-plus">+</span>
          </span>
          <em class="btn-text">{{$t('content.event.EventReservation.txt128')}}</em>
        </button>
      </div>
      </template>
      <template v-else>
        <div class="no-list-wrap">
          <div class="no-list">
            <h6 data-aos="flip-left" class="aos-init aos-animate">{{$t('content.event.EventReservation.txt129')}}</h6>
          </div>
          <div class="btn-wrap">
            <a href="#" @click="goBack" class="back">Back</a>
          </div>
        </div>
      </template>
    </div>
    <!-- Reservation List -->
  </div>
</template>

<script>
import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion, getDateString } from '@/common/helperUtils';

export default {
  name: 'ReservationHistory',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {},
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      vunues: [],
      statusType: true,
      increase: 10,
    };
  },
  created() {
    this.retrieveVenue();
    this.getDateString = getDateString;
  },
  methods: {
    retrieveVenue() {
      this.portalApiClient({
        url: APIs.MYPAGE_VENUE_LIST,
        data: {
          data: {
            memberId: this.$store.getters.getUserInfo().id
          },
          lang: this.$i18n.locale
        },
      }).then(({ data }) => {
        console.log(data.list)
        this.vunues = data.list;
        this.statusType = !!data.list.length;
      });
    },
    getVenueName(codes) {
      const venues = [];
      codes.forEach((v) => {
        venues.push(this.$t(`commonCode.${v.code_place_event}`));
      });
      return venues.join(', ');
    },
    addVunues() {
      this.increase += 10;
    },
    goBack() {
      this.$router.go(-1);
    },
    
  },
};
</script>
